<template>
	<div class="container-fluid h-100">
		<div class="row">
			<div class="container" style="height: 100vh;">
				<div class="row">
					<div class="col-md-7 mx-auto mt-5">
						<div class="text-center">
							<img class="img-fluid" width="100px;" height="30px;" src="@/assets/images/logo.svg?v=1" alt="Logo" />
						</div>
						<div class="card border-0 shadow-sm mt-3">
							<div class="card-header text-center bg-white border-0 mt-3">
								<h3>Register for an account</h3>
								<p>Register for zend.lk account to start sending messages to your customers.</p>
							</div>
							<div class="card-body">

								<div class="alert alert-danger" v-if="error">{{ error }}</div>

								<div class="form-row">
									<div class="col mr-2">
										<div class="form-group">
											<label for="first_name">First Name</label>
											<b-form-input v-model="field.first_name" type="text" class="form-control input-lg" :state="validation.first_name" placeholder="John"></b-form-input>
											<b-form-invalid-feedback :state="validation.first_name">{{ errors.first_name }}</b-form-invalid-feedback>
										</div>
									</div>
									<div class="col ml-2">
										<div class="form-group">
											<label for="last_name">Last Name</label>
											<b-form-input v-model="field.last_name" type="text" class="form-control input-lg" :state="validation.last_name" placeholder="Doe"></b-form-input>
											<b-form-invalid-feedback :state="validation.last_name">{{ errors.last_name }}</b-form-invalid-feedback>
										</div>
									</div>
								</div>

								<div class="form-row mt-1">
									<div class="col mr-2">
										<div class="form-group">
											<label for="email">Email</label>
											<b-form-input v-model="field.email" type="email" class="form-control input-lg" :state="validation.email" placeholder="john@company.com"></b-form-input>
											<b-form-invalid-feedback :state="validation.email">{{ errors.email }}</b-form-invalid-feedback>
										</div>
									</div>
									<div class="col ml-2">
										<div class="form-group">
											<label for="mobile">Mobile</label>
											<b-form-input v-model="field.mobile" type="text" class="form-control input-lg" :state="validation.mobile" placeholder="+94777123456"></b-form-input>
											<b-form-invalid-feedback :state="validation.mobile">{{ errors.mobile }}</b-form-invalid-feedback>
										</div>
									</div>
								</div>

								<div class="form-row mt-1">
									<div class="col mr-2">
										<div class="form-group">
											<label for="password">Password</label>
											<b-form-input v-model="field.password" type="password" class="form-control input-lg" :state="validation.password" placeholder="Password"></b-form-input>
											<b-form-invalid-feedback :state="validation.password">{{ errors.password }}</b-form-invalid-feedback>
										</div>
									</div>
								</div>

								<button v-on:click="register()" class="btn btn-primary w-100 mt-3">Register</button>
								<p class="mt-2">By clicking this button, you agree to our Terms, Privacy Policy and Security Policy.</p>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Register",

	data: () => ({
		error: null,
		field: {
			first_name: '',
			last_name: '',
			email: '',
			mobile: '',
			password: ''
		},
		validation: {
			first_name: null,
			last_name: null,
			email: null,
			mobile: null,
			password: null
		},
		errors: {
			first_name: null,
			last_name: null,
			email: null,
			mobile: null,
			password: null
		}
	}),

	methods: {
		register() {

			/**
			 * We have to disptach POST request with the registration
			 * details for make the user register with the service and
			 * then we can act based on the return data.
			 */
			this.error = null;
			this.$store.dispatch("user/register", {
				first_name: this.field.first_name,
				last_name: this.field.last_name,
				email: this.field.email,
				mobile: this.field.mobile,
				password: this.field.password
			}).then(() => this.$router.push("/home")).catch(Response => {
				if (Response.status == "failed") {
					this.error = Response.errors.message
				}
			})

		}
	}

}
</script>

<style lang="scss" scoped>
.container-fluid {
	font-family: "Open Sans";
	background-color: #F2F6FF;

	.card {
		.card-header {
			h3 {
				font-size: 22px;
				font-weight: 600;
				color: #3d5465;	
			}
			p {
				font-size: 13px;
				font-weight: 600;
				color: #888888;
				text-align: center;
			}
		}

		.card-body {
			p {
				font-size: 11px;
				font-weight: 600;
				color: #777777;
				text-align: center;
			}

			button {
				padding: 12px 0px;
				font-size: 14px;
				font-weight: 600 !important;
				background-color: #1292ee;
			}
		}
	}
}
</style>
